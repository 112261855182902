export enum AppRoutes {
  General = '/',
  Contacts = '/contacts',
  About = '/about',
  News = '/news',
  NewsArticle = '/news/article/:id',
  Products = '/products',
  Certificates = '/certificates',
  Production = '/production',
  ProductionCognac = '/production-cognac',
  ProductionVodka = '/production-vodka',
  Category = '/category/:id',
  Brand = '/brand/:id',
}

export const RouteMeta: Record<
  AppRoutes,
  {
    title: string;
  }
> = {
  [AppRoutes.About]: { title: 'О компании' },
  [AppRoutes.Contacts]: { title: 'Контакты' },
  [AppRoutes.General]: { title: 'КВК групп' },
  [AppRoutes.Certificates]: { title: 'Сертификаты' },
  [AppRoutes.News]: { title: 'Новости' },
  [AppRoutes.NewsArticle]: { title: 'Статья' },
  [AppRoutes.Production]: { title: 'Производство' },
  [AppRoutes.ProductionCognac]: { title: 'Производство Коньяка' },
  [AppRoutes.ProductionVodka]: { title: 'Производство Водки' },
  [AppRoutes.Products]: { title: 'Продукция' },
  [AppRoutes.Category]: { title: 'Категория' },
  [AppRoutes.Brand]: { title: 'Бренд' },
};
