import React from 'react';
import classnames from 'classnames';
import Divider from '@/components/divider/divider.component';
import {
  Disclaimer,
  DisclaimerTwoLines, LogoWhite,
} from '@i/index';
import Container from '@c/container/container.component';
import { FooterPropsInterface } from './footer.interfaces';
import styles from './footer.module.css';
import { FOOTER_NAV_ITEMS, FOOTER_TEXTS } from './footer.data';

const Footer: React.FunctionComponent<FooterPropsInterface> = () => {
  const navList = (
    <ul className={styles.nav}>
      {FOOTER_NAV_ITEMS.map(
        (thisItem) => (
          <li key={thisItem.text}>
            <a href={thisItem.link}>{thisItem.text}</a>
          </li>
        ),
      )}
    </ul>
  );

  return (
    <footer className={styles.root}>
      <Divider />

      <Container className={styles.main}>
        <div className={styles.top}>
          <LogoWhite className={styles.logo} />
        </div>

        <div className={styles.content}>
          <p className={styles.description}>{FOOTER_TEXTS.description}</p>
          {/* <p className={styles.copyright}>{FOOTER_TEXTS.copyright}</p> */}

          {navList}

          <div className={styles.contacts}>
            <p>{FOOTER_TEXTS.mobile}</p>
            <p><a href={`mailto:${FOOTER_TEXTS.email}`}>{FOOTER_TEXTS.email}</a></p>
            <p>{FOOTER_TEXTS.address}</p>
          </div>
        </div>
      </Container>

      <Divider isSimple />

      <div className={styles.footer}>
        <Container>
          <Disclaimer
            className={classnames(styles.disclaimer, styles.disclaimerIsWide)}
          />
          <DisclaimerTwoLines
            className={classnames(styles.disclaimer, styles.disclaimerIsCompact)}
          />
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
