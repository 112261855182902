import { gql } from '@apollo/client';
import { useMapper } from '../common.helpers';
import productionDetailsMap from './production-details.map';

const productionDetailsQuery = (type: 'vodka' | 'cognac') => gql`{
  ${type === 'cognac' ? 'proizvodstvoKonyak' : 'proizvodstvoVodka'} {
    data {
      attributes {
         data {
          title
          description
          picture {data{attributes{url}}}
          parts {
            title
            header
            description
            features
            mainPicture {data{attributes{url}}}
            bgPicture {data{attributes{url}}}
          }
        }
      }
    }
  }
}`;

const useProductionDetailsPageQuery = (type: 'vodka' | 'cognac') => useMapper(productionDetailsQuery(type), productionDetailsMap);

export default useProductionDetailsPageQuery;
