import { RouteMeta } from '@/routes.constants';
import React, { useEffect } from 'react';
import PageProps from './page.interfaces';

const Page = ({
  route,
  children,
}: React.PropsWithChildren<PageProps>) => {
  useEffect(() => {
    const { title } = RouteMeta[route];

    document.title = title;
  }, [route]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  );
};

export default Page;
