import ProductionDetailsData from '@/pages/production-details/production-details.interfaces';
import { unwrapCMS } from '../common.helpers';
import { ProductionDetailsCognacResponse, ProductionDetailsVodkaResponse } from './production-details.interfaces';
import bgSecondaryPicture from '../../components/production-steps/assets/secondary1.svg';

function productionDetailsMap(
  data: ProductionDetailsVodkaResponse | ProductionDetailsCognacResponse,
): ProductionDetailsData {
  const {
    title,
    picture,
    parts,
  } = unwrapCMS('proizvodstvoVodka' in data ? data.proizvodstvoVodka : data.proizvodstvoKonyak).data;

  return {
    arch: {
      title,
      picture: unwrapCMS(picture).url,
    },

    steps: parts.map(({
      title: stepTitle,
      header,
      description,
      features,
      mainPicture,
      bgPicture,
    }, inx) => ({
      index: inx + 1,
      title: stepTitle,
      header,
      description,
      picture: unwrapCMS(mainPicture).url,
      bgMainPicture: unwrapCMS(bgPicture).url,
      bgSecondaryPicture,
      features,
      reverse: inx % 2 !== 0,
    })),
  };
}

export default productionDetailsMap;
