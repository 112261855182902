import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import env from 'react-dotenv';
import { onError } from '@apollo/client/link/error';

const { host, hostname, protocol } = window.location;
const getHost = (): string => (
  ['localhost', 'review'].some((item) => hostname.includes(item))
    ? `${env?.CMS_HOST ?? 'https://cms.kvk.deluxetest.ru'}/graphql`
    : `${protocol}//cms.${host}/graphql`
);

const link = from([
  onError(() => {}),
  new HttpLink({
    uri: getHost(),
  }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
