import React from 'react';
import styles from './loader.module.css';

const Loader: React.FunctionComponent<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => (
  <div
    {...props}
    className={styles.root}
  />
);

export default Loader;
