import React from 'react';
import classNames from 'classnames';
import styles from './divider.module.css';
import { DividerPropsInterface } from './divider.interfaces';
import { ReactComponent as DiamondIcon } from './assets/diamond-icon.svg';

const Divider: React.FunctionComponent<DividerPropsInterface> = ({
  className,
  isSimple = false,
}) => (
  <div className={classNames(styles.root, className)}>
    {!isSimple && <DiamondIcon className={styles.icon} />}
  </div>
);

export default Divider;
