import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './age-gate.module.css';
// import logo from './logo.png';
import logoSVG from './logo.svg';

const root = document.getElementById('ov-root');

const AgeGate = () => {
  const [choice, setChoice] = useState(null);
  const [ageConfirmed, setAgeConfirmed] = useState(false);

  useEffect(() => {
    let ageConfirmedCookie = false;
    if (!document || typeof document.cookie === 'undefined') {
      ageConfirmedCookie = true;
    } else {
      const val = document.cookie
        .split('; ')
        .find((row) => row.startsWith('ageConfirmed='))
        ?.split('=')[1];
      if (val) {
        ageConfirmedCookie = true;
      }
    }

    setAgeConfirmed(ageConfirmedCookie);

    if (!ageConfirmedCookie) {
      document.body.classList.add('age-gate-displayed');
    }
    return () => {
      document.body.classList.remove('age-gate-displayed');
    };
  }, [ageConfirmed]);

  const yesBtnHandler = () => {
    document.cookie = 'ageConfirmed=1; path=/';
    setChoice(1);
    setAgeConfirmed(true);
  };

  if (ageConfirmed) {
    return null;
  }

  return ReactDOM.createPortal(
    (
      <div className={styles.root} style={{ display: ageConfirmed ? 'none' : 'block' }}>

        {(choice === 0) && (
          <div className={styles.container}>
            <div className={styles.warning}>
              Извините, Вы&nbsp;не&nbsp;можете зайти на&nbsp;сайт,
              так как представленная на&nbsp;сайте информация,
              предназначена
              <br />
              &nbsp;для лиц старше 18&nbsp;лет.
            </div>
            <div className={styles.buttons}>
              <button className={styles.wide} type="button" onClick={() => setChoice(null)}>Попробовать ещё раз</button>
            </div>
          </div>
        )}

        {(choice === null) && (
          <div className={styles.container}>
            <div className={styles.title}>
              Вы старше 18 лет?
            </div>
            <div className={styles.buttons}>
              <button type="button" onClick={yesBtnHandler}>Да</button>
              <button type="button" onClick={() => setChoice(0)}>Нет</button>
            </div>
          </div>
        )}

        <div className={styles['logo-container']}>
          <img className={styles.logo} src={logoSVG} alt="КВК групп" />
        </div>
      </div>
    ),
    root,
  );
};

export default AgeGate;
