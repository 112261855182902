import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from '@c/footer/footer.component';
import { ApolloProvider } from '@apollo/client';
import client from '@api/client';
import { AppRoutes } from './routes.constants';
import Header from './components/header/header.component';
import Page from './components/page/page.component';
import AgeGate from './components/age-gate/age-gate.component';
import Loader from './components/loader/loader.component';
import useProductionDetailsPageQuery from './api/production-detals/production-details.query';

const BrandPage = React.lazy(() => import('./pages/brand/brand.page'));
const GeneralPage = React.lazy(() => import('./pages/general/general.page'));
const ProductionGeneral = React.lazy(() => import('./pages/production-general/production-general.page'));
const ProductionDetails = React.lazy(() => import('./pages/production-details/production-details.page'));
const ContactsPage = React.lazy(() => import('./pages/contacts/contacts.page'));
const AboutPage = React.lazy(() => import('./pages/about/about.page'));
const ProductsPage = React.lazy(() => import('./pages/products/products.page'));
const CategoryPage = React.lazy(() => import('./pages/category/category.page'));
const NewsPage = React.lazy(() => import('./pages/news/news.page'));
const NewsArticlePage = React.lazy(() => import('./pages/news-article/news-article.pages'));

const ProductionDetailsPage: React.FC<{ type: 'vodka' | 'cognac' }> = ({
  type,
}) => {
  const { data } = useProductionDetailsPageQuery(type);

  return data ? <ProductionDetails {...data} /> : null;
};

const routeElements = new Map<AppRoutes, JSX.Element>([
  [AppRoutes.General, <GeneralPage />],
  [AppRoutes.Production, <ProductionGeneral />],
  [AppRoutes.ProductionVodka, <ProductionDetailsPage type="vodka" />],
  [AppRoutes.ProductionCognac, <ProductionDetailsPage type="cognac" />],
  [AppRoutes.Contacts, <ContactsPage />],
  [AppRoutes.About, <AboutPage />],
  [AppRoutes.Products, <ProductsPage />],
  [AppRoutes.Category, <CategoryPage />],
  [AppRoutes.News, <NewsPage />],
  [AppRoutes.NewsArticle, <NewsArticlePage />],
  [AppRoutes.Brand, <BrandPage />],
]);

const App = () => (
  <ApolloProvider client={client}>
    <Header />
    <Routes>
      {
        Array.from(routeElements.keys()).map((route) => (
          <Route
            key={route}
            path={route}
            element={(
              <Page route={route}>
                <React.Suspense
                  fallback={(
                    <Loader
                      style={{
                        position: 'absolute', top: '1em', left: 0, right: 0,
                      }}
                    />
                  )}
                >
                  {routeElements.get(route)}
                </React.Suspense>
              </Page>
            )}
          />
        ))
      }
    </Routes>
    <Footer />
    <AgeGate />
  </ApolloProvider>
);

export default App;
