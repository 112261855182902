/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { ApolloError, DocumentNode, useQuery } from '@apollo/client';
import { CMSDataWrapper, CMSDataWrapperArray } from './common.interfaces';

export function unwrapCMS<TData>(wrapped: CMSDataWrapper<TData>): TData {
  return wrapped?.data?.attributes;
}
export function unwrapCMSArray<TData>(wrapped: CMSDataWrapperArray<TData>): TData[] {
  return wrapped?.data?.map(({ attributes }) => attributes) ?? [];
}

export function useMapper<TResult, TMapped>(
  query: DocumentNode,
  mapper: (data: TResult) => TMapped,
): { data: TMapped | null, loading: boolean, error: ApolloError | undefined } {
  const [mapped, setMapped] = useState<TMapped | null>(null);
  const { data, loading, error } = useQuery<TResult>(query, { errorPolicy: 'ignore' });

  useEffect(() => {
    if (!loading && data) {
      setMapped(mapper(data));
    }
  }, [data]);

  return {
    data: mapped,
    error,
    loading,
  };
}
