import { AppRoutes } from '@/routes.constants';

export const FOOTER_TEXTS = {
  description:
    'КВК групп входит в число крупнейших алкогольных компаний России, имеет собственные производственные активы в Крыму, управляет портфелем брендов в различных категориях и дистрибуцией по всей стране, во всех каналах продаж.',
  copyright:
    'ООО «Крымская Водочная Компания» является эксклюзивным дистрибутором продукции ООО «КД Коктебель» на территории РФ.',
  mobile: '+7(495)783-1130',
  email: 'info@cvc.com.ru',
  address: 'Москва, Пресненская набережная 6, строение 2, башня «Империя»',
};

export const FOOTER_NAV_ITEMS = [
  {
    text: 'о компании',
    link: AppRoutes.About,
  },
  {
    text: 'новости',
    link: AppRoutes.News,
  },
  {
    text: 'продукция',
    link: AppRoutes.Products,
  },
  {
    text: 'контакты',
    link: AppRoutes.Contacts,
  },
  {
    text: 'производство',
    link: AppRoutes.Production,
  },
  {
    text: 'сертификаты',
    link: `${AppRoutes.About}#certificates`,
  },
];
