import React, { useState } from 'react';
import {
  Burger,
  CloseLines,
  LogoWhite,
} from '@/icons';
import { AppRoutes } from '@/routes.constants';
import classNames from 'classnames';
import HeaderItem from './header.interfaces';
import styles from './header.module.css';

const items: HeaderItem[] = [
  {
    link: AppRoutes.About,
    name: 'о компании',
  },
  {
    link: AppRoutes.Products,
    name: 'продукция',
  },
  {
    link: AppRoutes.News,
    name: 'новости',
  },
  {
    link: AppRoutes.Contacts,
    name: 'контакты',
  },
];

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);

  const menuItems = items.map((thisItem, index) => (
    <a
      key={thisItem.name}
      className={styles.item}
      href={thisItem.link}
      style={{
        '--index': index,
      } as React.CSSProperties}
    >
      {thisItem.name}
    </a>
  ));

  return (
    <div
      className={classNames(styles.root, {
        [styles.isOpened]: isOpened,
      })}
    >
      <div className={styles.illustrations} />

      <a className={styles.logoLink} href={AppRoutes.General}>
        <LogoWhite className={styles.logo} />
      </a>

      <Burger
        className={styles.burger}
        onClick={() => setIsOpened(!isOpened)}
      />
      <CloseLines
        className={styles.close}
        onClick={() => setIsOpened(false)}
      />

      <nav className={styles.list}>
        {menuItems}
      </nav>
    </div>
  );
};

export default Header;
