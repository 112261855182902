import React from 'react';
import classnames from 'classnames';
import styles from './container.module.css';
import { ContainerPropsInterface } from './container.interfaces';

const Container: React.FunctionComponent<ContainerPropsInterface> = ({
  children,
  className,
  hasGrid = false,
  ...props
} = {}) => (
  <div
    {...props}
    className={classnames(styles.root, className, {
      [styles.hasGrid]: hasGrid,
    })}
  >
    {children}
  </div>
);

export default Container;
